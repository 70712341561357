import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import Media from '../../components/media';
import LongForm from '../../components/long-form';

import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageLead,
  InpageBody
} from '../../styles/inpage';

import Media404Cover from '../../media/layout/404/404-cover--largeup.jpg';

const NotFoundPage = ({ location }) => {
  return (
    <Layout
      location={location}
      title='Page not found'
      metaImage={Media404Cover}
    >
      <Inpage>
        <InpageHeader leadLayout='aside'>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>Page not found.</InpageTitle>
            </InpageHeadline>
            <InpageLead>
              <p>Or 404 if you speak internet.</p>
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded>
          <Media
            size='cover'
            decoration='bottom'
            src={Media404Cover}
            alt='Page not found'
            attribution={{
              name: 'Damien Roué',
              url: 'https://www.flickr.com/photos/damienroue/'
            }}
          />
          <LongForm>
            <p>This page may have been archived or removed.</p>
            <p>
              You might find an older snapshot of this page at the{' '}
              <a
                href='https://archive.org/web/'
                title='Find on Internet Archive'
              >
                Internet Archive
              </a>
              .<br /> If you think this page should be here let us know via{' '}
              <a
                href='mailto:info@developmentseed.org'
                title='Send us an email'
              >
                email
              </a>{' '}
              or on{' '}
              <a
                href='https://twitter.com/developmentseed'
                title="Let's talk on Twitter"
              >
                twitter
              </a>
              .
            </p>
          </LongForm>
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default NotFoundPage;

NotFoundPage.propTypes = {
  location: PropTypes.object
};
